import './css/App.css';
import Cabecera from './componentes/Cabecera';
import CursoLista from './componentes/CursoLista';
import RutasPrivadas from './componentes/rutas/PrivateRoute';
import React, { useEffect, useState } from 'react';
import { db } from './componentes/db/db';
import { AuthProvider, useAuth } from './componentes/db/Auth';
import { BrowserRouter as Router } from 'react-router-dom';
import { getDoc } from 'firebase/firestore';

const Inicializador = () => {
  const { currentUser, signOut } = useAuth();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const usuariosCollectionRef = db.collection('Usuarios');
    usuariosCollectionRef
      .where('email', '==', currentUser.email)
      .get()
      .then((querySnapshotUsuario) => {
        const promises = [];
  
        querySnapshotUsuario.forEach((doc) => {
          const cursoRefs = doc.data().curso;
          cursoRefs.forEach((cursoRef) => {
            const promise = getDoc(cursoRef).then((cursoDoc) => cursoDoc.data());
            promises.push(promise);
          });
        });
  
        Promise.all(promises)
          .then((cursosData) => {
            setTasks(cursosData);
          })
          .catch((error) => {
            console.log('Error al obtener los documentos de los cursos:', error);
          });
      })
      .catch((error) => {
        console.log('Error al obtener los documentos de los usuarios:', error);
      });
  }, [currentUser]);
  

  const getPercentComplete = () => {
    const completedTasks = tasks.filter((task) => task.completed);
    return Math.round((completedTasks.length / tasks.length) * 100);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      // Realizar acciones después de cerrar sesión
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Cabecera percentComplete={getPercentComplete()} />
      <CursoLista tasks={tasks} setTasks={setTasks} />
      <footer>
        <span>Información Footer</span>
      </footer>
    </>
  );
};

const App = () => {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <RutasPrivadas Inicializador={Inicializador} />
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
