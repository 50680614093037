import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
const firebaseConfig = {
    apiKey: "AIzaSyDQjPqx-PjkZA6ZjrHV4TLGuKR9xZG6xPc",
    authDomain: "ifcd0111-danielsalas.firebaseapp.com",
    databaseURL: "https://ifcd0111-danielsalas-default-rtdb.firebaseio.com",
    projectId: "ifcd0111-danielsalas",
    storageBucket: "ifcd0111-danielsalas.appspot.com",
    messagingSenderId: "691811463264",
    appId: "1:691811463264:web:130e32584768f048761305"
  };

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();

export { auth, storage, db };