// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import  "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDQjPqx-PjkZA6ZjrHV4TLGuKR9xZG6xPc",
  authDomain: "ifcd0111-danielsalas.firebaseapp.com",
  databaseURL: "https://ifcd0111-danielsalas-default-rtdb.firebaseio.com",
  projectId: "ifcd0111-danielsalas",
  storageBucket: "ifcd0111-danielsalas.appspot.com",
  messagingSenderId: "691811463264",
  appId: "1:691811463264:web:130e32584768f048761305"
};
firebase.initializeApp(firebaseConfig);

//console.info(firebaseConfig);
// Initialize Firebase
 const db = firebase.firestore();

 export { db, firebaseConfig};
